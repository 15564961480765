<template>
  <div class="container" ref="container">
    <div  @dblclick="cardClick">
      <el-card class="box-card" v-for="item in devices" :key="item.deviceId" style="margin-left: 20px;" >
        <div slot="header" class="clearfix">
          <span style="font-size: 13px;font-weight: 1000;color: #409EFF;">{{item.supplierTypeDisplay}}-{{ item.deviceTypeDisplay }}</span>
          <el-button :hidden="!item.status" class="mark-style" type="success" circle size="mini"></el-button>
          <el-button :hidden="item.status" class="mark-style" type="danger" circle size="mini"></el-button>
        </div>
        <div style="line-height: 20px;">
          <div style="font-size: 11px;">
          设备编号：{{item.clientId}}
        
        </div>
        <div style="font-size: 11px;">
          设备编号：{{item.clientId}}
        
        </div>
        </div>
       
      </el-card>
    </div>

  </div>
</template>

<script>
import iotApi from "@/api/iot.js";


export default {
  data() {
    return {
      devices: []
    };
  },
  components: {
  },
  mounted() {
  },
  created() {
    this.page();
  },
  methods: {
    page() {
      let params = {
        "keyWords": "",
        "pageIndex": 0,
        "pageSize": 1000

      }
      this.$post(iotApi.page, params).then((res) => {
        if (res.success && res.statusCode == 200) {

          this.devices = res.data.records
          console.log(this.devices)
        }
      });
    },
    cardClick(){
      alert(1)
    }
  }
}
</script>

<style>
.container {
  display: flex;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 10px;
}
.clearfix{
  height: 5px;
  margin-top: -10px;
  
}
.clearfix:before,

.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.box-card {
  width: 210px;
}

.mark-style {
  float: right;
 
  margin: -5px -16px 0px 0px;
}</style>